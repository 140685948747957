<template>
  <div class="header">
    <div class="header_item1">
      <div class="button"
           @click="toHome()">
        <div>首页</div>
        <div class="line"></div>
      </div>
    </div>
    <div class="header_item2">
      <div>{{ time }}</div>
      <div @click="register()"
           v-if="id !== ''">供应商注册</div>
      <div @click="login()"
           v-if="id !== ''">登录</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      time: "",
      id: "",
    };
  },
  mounted () {
    this.today();
    this.id = sessionStorage.getItem("id");
  },
  methods: {
    today () {
      var date = new Date();
      var year = date.getFullYear();
      var month =
        date.getMonth() >= 9
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      var day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      var xingqi = date.getDay();
      var chinese;
      switch (xingqi) {
        case 0:
          chinese = "日";
          break;
        case 1:
          chinese = "一";
          break;
        case 2:
          chinese = "二";
          break;
        case 3:
          chinese = "三";
          break;
        case 4:
          chinese = "四";
          break;
        case 5:
          chinese = "五";
          break;
        case 6:
          chinese = "六";
          break;
      }
      this.time =
        "今天是" + year + "年" + month + "月" + day + "日 星期" + chinese;
    },
    toHome () {
      this.$router.push({
        path: "/",
        query: {
          id: sessionStorage.getItem("id"),
        },
      });
    },
    // 登录跳转
    login () {
      window.open(sessionStorage.getItem("loginUrl"), "_blank");
    },
    // 注册跳转
    register () {
      this.$router.push({
        path: "/register",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.center {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.header {
  // width: 1920px;
  height: 96px;
  background: rgba(9, 9, 28, 0.7);
  box-sizing: border-box;
  padding: 0 360px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .header_item1 {
    height: 96px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    .button {
      width: 50px;
      height: 96px;
      font-size: 16px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #ffffff;
      position: relative;
      cursor: pointer;
      .center();
      .line {
        width: 32px;
        height: 2px;
        background: #2957ff;
        position: absolute;
        left: 50%;
        bottom: 29px;
        transform: translateX(-50%);
      }
    }
  }
  .header_item2 {
    height: 96px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    div {
      .center();
      font-family: PingFang-SC-Medium, PingFang-SC;
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      border: 1px solid rgba(255, 255, 255, 0.49);
    }
    div:nth-child(1) {
      font-size: 14px;
      font-weight: 500;
      border: none;
    }
    div:nth-child(2) {
      width: 128px;
      height: 46px;
      margin-left: 32px;
      margin-right: 24px;
      cursor: pointer;
    }
    div:nth-child(3) {
      width: 97px;
      height: 46px;
      cursor: pointer;
    }
  }
}
</style>
