<template>
  <div class="list_detail">
    <div class="nav">
      <Header></Header>
      <div class="nav_flex">
        <div class="title">{{ title }}</div>
        <!-- <img class="title" :src="require('./static/image/title.png')" alt="" /> -->
        <!-- <div class="search">
          <img :src="require('./static/image/search.png')" alt="" />
          <input
            type="text"
            placeholder="请输入关键词"
            placeholder_class="placeholder_class"
          />
          <div class="button">搜索</div>
        </div> -->
      </div>
    </div>
    <div class="info">
      <div class="title">
        <div @click="backTo()"
             v-if="type1 == 0">通知公告</div>
        <div @click="backTo()"
             v-if="type1 !== 0">
          {{
            type1 == 1
              ? "自行采购公告&nbsp;/&nbsp;"
              : "网上竞价平台&nbsp;/&nbsp;"
          }}
        </div>
        <div v-if="type1 !== 0 && type2 == 0">
          &nbsp;自行采购&nbsp;/&nbsp;正文
        </div>
        <div v-if="type1 !== 0 && type2 == 1">
          &nbsp;招标公告&nbsp;/&nbsp;正文
        </div>
        <div v-if="type1 !== 0 && type2 == 2">
          &nbsp;结果公告&nbsp;/&nbsp;正文
        </div>
        <div v-if="type1 !== 0 && type2 == 3">
          &nbsp;补充公告&nbsp;/&nbsp;正文
        </div>
      </div>
      <div class="info_div">
        <!-- <div class="notice_title">{{ noticeTitle }}</div> -->
        <div v-html="html"></div>
        <div class="files"
             v-if="filesArr[0].fileName">
          <div class="title">附件文件</div>
          <div class="files_div">
            <div class="file"
                 v-for="(item, index) in filesArr"
                 :key="index">
              <img :src="item.imgSrc"
                   alt="" />
              <div class="content">
                <div class="name">{{ item.fileName }}</div>
                <div class="control">
                  <!-- <div>预览</div> -->
                  <div @click="downLoad(item.fileUrl)">下载</div>
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Fotter :isPosition="true"></Fotter>
  </div>
</template>
<script>
import Header from "../../components/common/header.vue";
import Fotter from "../../components/common/fotter.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { queryToNoticeDetail } from "../../request/detail";

export default {
  name: "list_detail",
  components: { Header, Fotter },
  data () {
    return {
      type1: 0,
      type2: 0,
      html: "",
      noticeTitle: "",
      filesArr: [
        {
          imgSrc: require("./static/image/word.png"),
          fileName: "",
          fileUrl: "",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      title: (state) => state.title.title,
    }),
  },
  created () {
    console.log(this.$route.query);
    this.type1 = Number(this.$route.query.type1);
    this.type2 = Number(this.$route.query.type2);
    this.queryToNoticeDetail();
  },
  methods: {
    backTo () {
      this.$router.go(-1);
      //    history.go(-1)
    },
    downLoad (url) {
      window.open(url, "_blank");
    },
    queryToNoticeDetail () {
      queryToNoticeDetail({ noticeId: this.$route.query.noticeId }).then(
        (res) => {
          this.html = res.data.noticeContent;
          this.noticeTitle = res.data.noticeTitle;
          this.filesArr[0].fileName = res.data.fileName;
          this.filesArr[0].fileUrl = res.data.fileUrl;
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
.center {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.list_detail {
  // width: 1920px;
  min-height: 100vh;
  background: #f2f3f7;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 150px;

  .nav {
    height: 251px;
    background-image: url("./static/image/nav.png");
    background-size: 100% 100%;

    .nav_flex {
      height: 155px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      .title {
        // width: 383px;
        height: 68px;
        // margin-right: 202px;
        .center();
        font-size: 48px;
        color: white;
        font-weight: bold;
      }
      .search {
        width: 615px;
        height: 66px;
        background: #ffffff;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding-left: 24px;
        position: relative;

        img {
          width: 22px;
          height: 22px;
          margin-right: 16px;
        }
        input {
          outline: none;
          border: none;
          font-size: 16px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
        }
        /deep/ .placeholder_class {
          color: #b7bcce;
        }
        .button {
          width: 127px;
          height: 50px;
          background: #2957ff;
          font-size: 16px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #ffffff;
          cursor: pointer;
          .center();
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .info {
    box-sizing: border-box;
    padding: 0 360px;

    .title {
      height: 68px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      div:nth-child(1) {
        color: #2957ff;
        cursor: pointer;
      }
      div:nth-child(2) {
        color: #232428;
      }
    }
    .info_div {
      box-sizing: border-box;
      padding: 24px;
      background-color: #ffffff;
      .notice_title {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        margin: 10px 0;
      }
      .files {
        height: 174px;
        background: #f8f9fb;
        box-sizing: border-box;
        padding: 0 32px;
        margin-top: 32px;

        .title {
          height: 78px;
          line-height: 78px;
          font-size: 16px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #232428;
        }
        .files_div {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-items: center;

          .file {
            height: 64px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 64px;
              height: 64px;
              margin-right: 16px;
            }
            .content {
              .name {
                height: 22px;
                line-height: 22px;
                font-size: 16px;
                font-family: PingFang-SC-Bold, PingFang-SC;
                font-weight: bold;
                color: #232428;
                margin-bottom: 16px;
              }
              .control {
                width: 100px;
                height: 20px;
                line-height: 20px;
                font-size: 14px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #2957ff;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                position: relative;
                div {
                  cursor: pointer;
                }
                .line {
                  width: 1px;
                  height: 7px;
                  background: #d6e0ff;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translateX(-50%) translateY(-50%);
                }
              }
            }
          }
        }
      }
    }
  }
}
/deep/ table {
  width: 100%;
  border: 1px solid #999;
  border-spacing: 0; /*去掉单元格间隙*/
  margin: 20px 0;

  td {
    border: 1px solid #999;
    text-align: center;
  }
}
</style>
