<template>
  <div :class="isPosition ? 'fotter position' : 'fotter'">
    Copyright © 2021-2022 福建火柴数据有限公司提供技术支持
  </div>
</template>
<script>
export default {
  props: {
    isPosition: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="less" scoped>
.fotter {
  // width: 100vw;
  width: 100%;
  height: 60px;
  background: #30313f;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #ffffff;
  margin-top: 105px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.position {
  position: fixed;
  left: 0;
  bottom: 0;
}
</style>